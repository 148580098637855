import React from "react";

export default function LatestProjectVideo() {
  return (
    <div>
      <h1>Latest Project Videos</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2 sm:gap-14">
        <div>
          <iframe
            width="788"
            height="417"
            src="https://www.youtube.com/embed/q9QXZ3NWdIc"
            title="aqua finder, smart website with AI for water finder"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <iframe
            width="853"
            height="480"
            src="https://www.youtube.com/embed/Lyl0h4J1sXk"
            title="5gada e-commerce"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <iframe
            width="853"
            height="480"
            src="https://www.youtube.com/embed/0cE51k3XGRo"
            title="Pdf Editor"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <iframe
            src="https://www.youtube.com/embed/UuG5yWCUhYE"
            title="bet-rent demo vide"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>

        <div>
          <iframe
            width="853"
            height="480"
            src="https://www.youtube.com/embed/m3QsUKH_dZg"
            title="football fans social media"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <iframe
            width="853"
            height="480"
            src="https://www.youtube.com/embed/v5Yin7ssBek"
            title="PDF editor system"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <iframe
            width="853"
            height="480"
            src="https://www.youtube.com/embed/FLpZNtscbm0"
            title="Simple auth and product ad posting system"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <iframe
            src="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7071239727856705536?compact=1"
            frameborder="0"
            allowfullscreen=""
            title="Embedded post"
          ></iframe>
        </div>
      </div>
    </div>
  );
}
