/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable jsx-a11y/alt-text */
import { FaExternalLinkAlt } from "react-icons/fa";

import React, { useEffect, useState } from "react";
import { projects } from "./FeaturedProject";

export function HeaderProj() {
  const [scrollOffset, setScrollOffset] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollOffset(window.pageYOffset);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="try" style={{ height: "40vh" }}>
      <div
        style={{
          transform: `translateY(${scrollOffset * 0.5}px)`,
          backgroundPositionY: "bottom",
        }}
        class="parallax-bg"
      ></div>
    </div>
  );
}

export default function Projects() {
  return (
    <>
      <HeaderProj />
      <div className="content proj ">
        <div className="relative mx-auto -mt-20 w-full">
          <div className="  ">
            <img
              src="/Images/profile-pic.jpg"
              className="rounded-full h-28 w-28 object-cover"
              alt="profile pic"
            />
          </div>
        </div>
        <h1 id="experience">Projects</h1>
        <div className="Card_list projects-list">
          {projects.map((image, index) => (
            <div key={index} className="slide h-max card">
              <h2 className="text-xl">{image.title}</h2>
              <span>{image.date}</span> <br />
              <span>{image.role}</span>
              <p className="text-sm p-0 m-0">{image.desc}</p>
              <img
                className="bg-white"
                onClick={() => {
                  if (image.url !== null) {
                    window.open(image.url, "_blank");
                  }
                }}
                src={image.image}
                alt={`Slide ${index}`}
              />
            </div>
          ))}
        </div>
        <h1>Others</h1>
        <WebsiteGrid />
      </div>
    </>
  );
}

const WebsiteGrid = () => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
      <div className="relative bg-white rounded shadow-lg overflow-hidden">
        <iframe
          allowFullScreen
          className="w-full  h-96"
          src="https://inventorywhiz.com/"
          title="Inventory Whiz"
        />
        <a
          href="https://inventorywhiz.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="absolute top-4 right-4 text-gray-700 hover:text-blue-500"
          aria-label="Visit Inventory Whiz"
        >
          <FaExternalLinkAlt size={20} />
        </a>
      </div>
      <div className="relative bg-white rounded shadow-lg overflow-hidden">
        <iframe
          allowFullScreen
          className="w-full h-96"
          src="https://www.myscielab.us/"
          title="MYSIELAB"
        />
        <a
          href="https://www.myscielab.us/"
          target="_blank"
          rel="noopener noreferrer"
          className="absolute top-4 right-4 text-gray-700 hover:text-blue-500"
          aria-label="Visit MYSIELAB"
        >
          <FaExternalLinkAlt size={20} />
        </a>
      </div>
      <div className="relative bg-white rounded shadow-lg overflow-hidden">
        <iframe
          allowFullScreen
          className="w-full h-96"
          src="https://fluffy-plays.space/"
          title="fluffy-plays.space"
        />
        <a
          href="https://fluffy-plays.space/"
          target="_blank"
          rel="noopener noreferrer"
          className="absolute top-4 right-4 text-gray-700 hover:text-blue-500"
          aria-label="Visit fluffy-plays.space"
        >
          <FaExternalLinkAlt size={20} />
        </a>
      </div>
      <div className="relative bg-white rounded shadow-lg overflow-hidden">
        <iframe
          allowFullScreen
          className="w-full  h-96"
          src="https://skillspalace.com/"
          title="Skills Palace"
        />
        <a
          href="https://skillspalace.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="absolute top-4 right-4 text-gray-700 hover:text-blue-500"
          aria-label="Visit Skills Palace"
        >
          <FaExternalLinkAlt size={20} />
        </a>
      </div>

      <div className="relative bg-white rounded shadow-lg overflow-hidden">
        <iframe
          allowFullScreen
          className="w-full  h-96"
          src="https://space-sync.netlify.app/landing"
          title="Space Sync"
        />
        <a
          href="https://space-sync.netlify.app/landing"
          target="_blank"
          rel="noopener noreferrer"
          className="absolute top-4 right-4 text-gray-700 hover:text-blue-500"
          aria-label="Visit Space Sync"
        >
          <FaExternalLinkAlt size={20} />
        </a>
      </div>
      <iframe
        width="full"
        height="full"
        src="https://www.youtube.com/embed/m3QsUKH_dZg"
        title="football fans social media"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
    </div>
  );
};
